<template>
  <div>
    <BackgroundLayer v-if="showLayer" @click.native="closeLayer()" />
    <div v-if="showModalContainer" class="nav-modal-container">
      <LangModal v-if="showLang" class="fixed" @closemodal="closeLayer()" />
    </div>
    <nav class="navbar">
      <div class="navbar-wrapper">
        <a href="/">
          <img
            :src="require('@/assets/img/logos/logo.svg')"
            alt="Travel Afrique Logo"
            class="h-12"
          />
        </a>
        <LanguageSwitcher mode="solid" @click.native="openLang()" />
      </div>
    </nav>
    <main class="page-main">
      <section class="page-section">
        <PageLoader v-if="loading" />
        <div class="form" v-if="isCompleted && hasToken && !loading && !isSuccess">
          <i18n tag="h1" path="page.review.title" class="title">
            <span class="country">{{ country }}</span>
          </i18n>
          <div class="flex items-center w-full mb-8">
            <figure
              class="relative w-24 h-24 mr-3 overflow-hidden rounded-full"
            >
              <img
                :src="`${app_file_base_path}${partnerImg}`"
                class="absolute object-cover w-full h-full"
              />
            </figure>
            
            <div class="agency-details">
              <div class="agency-details-label">
                {{ $t('page.review.you_travelled_with')}}
              </div>
              <div class="agency-details-name">{{ agency }}</div>
          </div>
          </div>
          <Divider class="my-8" />
          <h4 class="font-semibold mt-8 mb-2">
            {{ $t('page.review.rate_your_experience', {n: agency})}}
          </h4>
          <Rating
            @click.native="validateRating"
            size="md"
            :is-static="false"
            @getRating="setRating"
          />
          <ErrorMessage :error-msg="$t('error.review.rating_is_required')" v-if="ratingErr" />
          <TextArea
            class="mt-8 block"
            v-model="review"
            :show-err-msg="reviewErr"
            :error-msg="$t('error.review.review_is_required')"
            @blurred="validateReview()"
            @input="validateReview()"
            :label="$t('page.review.label.tell_us_about_your_experience')"
            :placeholder="$t('page.review.placeholder.tell_us_about_your_experience')"
          />
          <ButtonPrimary
            :text="$t('cta.submit_review')"
            @click.native="submitReview()"
            class="mt-8 w-full"
            :loading="loading"
          />
        </div>
        <EmptyState
          v-if="!isCompleted && hasToken && !loading"
          icon-class="bx bxs-star"
          :title="$t('empty_state.review_already_exists.title')"
          :text="$t('empty_state.review_already_exists.text')"
          :use-link="true"
          link-path="/"
          :btn-text="$t('cta.go_to_homepage')"
        />
        <EmptyState
          v-if="!isCompleted && !hasToken && !loading"
          icon-class="bx bxs-error"
          :title="$t('empty_state.something_went_wrong.title')"
          icon-color="red"
          :text="$t('empty_state.something_went_wrong.text')"
          :use-link="true"
          link-path="/"
          :btn-text="$t('cta.go_to_homepage')"
        />
        <EmptyState
          v-if="isCompleted && hasToken && !loading && isSuccess"
          icon-class="bx bxs-like"
          :title="$t('empty_state.thanks_for_your_feedback.title')"
          :text="$t('empty_state.thanks_for_your_feedback.text')"
          :use-link="true"
          link-path="/"
          :btn-text="$t('cta.go_to_homepage')"
        />
      </section>
    </main>
  </div>
</template>
<script>
import Rating from "@/components/review/Rating.vue";
import TextArea from "@/components/inputs/TextArea.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import Divider from "@/components/dividers/Divider.vue";
import api from "@/api";
import ErrorMessage from "@/components/ErrorMessage.vue";
import EmptyState from "@/components/EmptyState.vue";
import LangModal from "@/components/modals/LangModal.vue";
import BackgroundLayer from "@/components/BackgroundLayer.vue";
import LanguageSwitcher from "@/components/switchers/LanguageSwitcher.vue";
import PageLoader from "@/components/preloaders/PageLoader.vue";
const countries = [
    {
      name: "Benin",
      en: "Benin",
      fr: "au Bénin"
    },
    {
      name: "Cape Verde",
      en: "Cape Verde",
      fr: "au Cap-Vert",
    },
    {
      name: "Côte d'Ivoire",
      en: "Côte d'Ivoire",
      fr: "en Côte d'Ivoire",
    },
    {
      name: "Democratic Republic of the Congo",
      en: "the Democratic Republic of the Congo",
      fr: "en République démocratique du Congo",
    },
    {
      name: "Ghana",
      en: "Ghana",
      fr: "au Ghana",
    },
    {
      name: "Kenya",
      en: "Kenya",
      fr: "en Kenya",
    },
    {
      name: "Morocco",
      en: "Morocco",
      fr:"au Maroc",
    },
    {
      name: "Namibia",
      en: "Namibia",
      fr: "en Namibie",
    },
    {
      name: "Rwanda",
      en: "Rwanda",
      fr: "au Rwanda",
    },
    {
      name: "Senegal",
      en: "Senegal",
      fr: "au Sénégal",
    },
    {
      name: "South Africa",
      en: "South Africa",
      fr: "en Afrique du Sud",
    },
    {
      name: "Tanzania",
      en: "Tanzania",
      fr: "en Tanzanie",
    },
  ];
export default {
  name: "Review",
  components: {
    Rating,
    TextArea,
    ButtonPrimary,
    Divider,
    ErrorMessage,
    EmptyState,
    LangModal,
    BackgroundLayer,
    LanguageSwitcher,
    PageLoader,
},
  data() {
    return {
      destination: undefined,
      partner: undefined,
      tripDate: undefined,
      partnerImg: undefined,
      review: "",
      rating: undefined,
      reviewErr: false,
      ratingErr: false,
      token: "",
      isCompleted: undefined,
      hasToken: undefined,
      loading: true,
      showLang: false,
      showLayer: false,
      isSuccess: false,
    };
  },
  methods: {
    openLang() {
      this.showLang = !this.showLang;
      this.showLayer = true;
      this.toggleBodyOverflow();
    },
    toggleBodyOverflow() {
      //Hide vertical overflow of body when a modal is opened
      if (this.showLayer === true) {
        document.body.classList.add("overflow-y-hidden");
      } else {
        document.body.classList.remove("overflow-y-hidden");
      }
    },
    closeLayer() {
      this.showLang = false;
      this.showLayer = false;
      this.toggleBodyOverflow();
    },
    setRating(rating) {
      this.rating = rating;
    },
    validateReview() {
      if (this.review.length < 1) {
        this.reviewErr = true;
      } else {
        this.reviewErr = false;
      }
      return this.reviewErr === true ? false : true;
    },
    validateRating() {
      if (this.rating === undefined) {
        this.ratingErr = true;
      } else {
        this.ratingErr = false;
      }

      return this.ratingErr === true ? false : true;
    },
    async submitReview() {
      let formData = new FormData();
      formData.append("token", this.token);
      formData.append("review", this.review);
      formData.append("rating", this.rating);

      let ratingVal = this.validateRating();
      let reviewVal = this.validateReview();

      if (ratingVal && reviewVal) {
        this.loading = true;
        await api
          .sendReview(formData)
          .then(() => {
            this.loading = false;
            this.isSuccess = true;
          })
          .catch((err) => console.log(err));
      }
    },
    setGenetive(name) {
      let lang = localStorage.getItem("lang") || "en";

      if (lang === "en") {
        let sLetters = ["s", "z", "x", "ś", "š", "ß"];
        let lastLetter = name[name.length - 1];
        if (sLetters.includes(lastLetter))
          return `${name}' agency`;
        else
          return `${name}'s agency`;
      }

      if (lang === "fr") {
       let vowels = ["A", "Ä", "À", "Á", "Â", "Æ", "Ã", "Å", "Ā", "É", "È", "Ê", "Ë", "Ė", "Î", "Ï", "Í", "Ī", "Ì", "O", "Ö", "Ô", "Ò", "Ó", "Õ", "Œ", "Ø", "Ō", "U", "Ü", "Û", "Ù", "Ú", "Ū", "Y", "Ÿ"];
        let firstLetter = name[name.length - 1];
        if (vowels.includes(firstLetter)) {
          return `l'agence d'${name}`;
        } else {
          return `l'agence de ${name}`;
        }
      }
    }  
  },
  computed: {
    agency() {
      return this.setGenetive(this.partner);
    },
    country() {
      let lang = localStorage.getItem("lang") || "en";
      let country = countries.find(country => country.name === this.destination);
      if (lang === "en") {
        return country.en;
      }
      return country.fr;
    },
    app_file_base_path() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
    showModalContainer() {
      return this.showLang;
    },
  },
  async beforeMount() {
    this.token = this.$route.query.token;
    if (this.token !== undefined) {
      let review = await api.getSingleReview(this.token).then((res) => {
        this.loading = false;
        return res.data.review;
      });
      this.destination = review.destination;
      this.partner = review.partner_name;
      this.partnerImg = review.partner_image;
      this.isCompleted = review.status === "completed" ? false : true;
      this.hasToken = true;
    } else {
      this.loading = false;
      this.hasToken = false;
    }
  },
};
</script>
<style scoped>
.title {
  @apply font-bold text-2xl mb-6 text-black-base;
}
.page-main {
  @apply w-full px-4 md:px-12 sm:bg-yellow-lighter;
  min-height: 100vh;
}

.page-section {
  @apply w-full max-w-screen-md;
  @apply relative transform -translate-x-1/2 left-1/2 py-8 sm:py-24;
}

.form {
  @apply max-w-lg sm:py-8 xs:px-4 sm:px-8  sm:border sm:border-grey-base;
  @apply rounded-md bg-white relative left-1/2 transform -translate-x-1/2;
}

.navbar {
  @apply top-0 z-50 flex flex-wrap items-center w-full h-20 px-4 bg-white md:px-12;
}

.navbar-wrapper {
  @apply relative flex items-center justify-between;
  @apply w-full h-12 max-w-screen-md;
  @apply transform -translate-x-1/2 left-1/2;
}

.agency-details-label {
  @apply font-sans text-sm leading-tight text-black-lighter;
}

.agency-details-name {
  @apply font-sans font-semibold leading-tight text-black-base;
}

.country {
  @apply inline-block text-black-base font-bold;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: #ffab1a;
  text-underline-offset: 8px;
  text-decoration-thickness: 6px;
}

.nav-modal-container {
  @apply fixed top-0 left-0 z-30 px-4 py-28;
  @apply flex justify-center items-center;
}
</style>