<template>
  <label :for="name" class="w-full">
    <span class="textarea-label">{{ label }}</span>
    <span v-if="showDescription" class="textarea-description">{{
      description
    }}</span>
    <textarea
      id="name"
      :class="[showErrMsg ? 'border-err' : 'border-default']"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      class="textarea"
      v-bind="$attrs"
      @blur="$emit('blurred')"
      @input="$emit('input', $event.target.value)"
    >
    </textarea>
    <ErrorMessage v-if="showErrMsg" :error-msg="errorMsg" />
  </label>
</template>
<script>
import ErrorMessage from "../ErrorMessage.vue";
export default {
  inheritAttrs: false,
  name: "TextArea",
  props: {
    showDescription: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: String,
    },
    errorMsg: {
      type: String,
      default: "Error message",
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
  },
  components: { ErrorMessage },
  methods: {},
};
</script>
<style scoped>
.textarea-label {
  @apply inline-block font-semibold;
}

.textarea-description {
  @apply block text-black-lighter mt-1 text-sm;
}

.textarea {
  @apply border block w-full resize-none rounded-lg h-32;
  @apply focus:border-teal-base focus:ring-teal-lighter focus:ring-2 mt-3;
  @apply text-black-base placeholder-black-lightest;
  @apply text-sm sm:text-base
}

.border-err {
  @apply border-red-base;
}

.border-default {
  @apply border-grey-dark;
}
</style>